import moment from 'moment'
import { IPageFilters } from 'services/filters/filters.types'
import { http } from 'services/http'
import { ISeafarer, ISeafarerMultipleResponse } from 'services/seafarers/seafarers.types'

export interface IFilterValue {
  id: string
  label: string
  name: string
}

interface IFilterSuffix {
  age: IFilterValue
  nationality: IFilterValue
  position: IFilterValue
  vessel: IFilterValue
  englishLevel: IFilterValue
  jobStatus: IFilterValue
  dpLicense: IFilterValue
  travelDocuments: IFilterValue
  certifications: IFilterValue
  endorsements: IFilterValue
  totalWorkExperienceYears: IFilterValue
  nearestAirport: IFilterValue
  visas: IFilterValue
  companyCandidateAccess: IFilterValue
  companyCandidateAccessType: IFilterValue
}

const filterSuffix = {
  age: '=',
  nationality: '.id=$in:',
  position: '.id=$in:',
  vessel: '.id=$in:',
  englishLevel: '.filter.id=$in:',
  // englishLevel: '.id=$in:',
  jobStatus: '.id=$in:',
  dpLicense: '.filter.id=$in:',
  travelDocuments: '.filter.id=$in:',
  certifications: '.filter.id=$in:',
  endorsements: '.filter.id=$in:',
  totalWorkExperienceYears: '=$gte:',
  nearestAirport: '=$ilike:',
  'experiences.imoNumber': '=',
  visas: '.id=$in:',
  cv: '=',
  companyCandidateAccess: '.company.id=$eq:',
  companyCandidateAccessType: '.company.id=$not:$eq:',
  jobApplications: '.status=$eq:',
}

const keyToRequest = {
  age: 'birthdate',
  nationality: 'nationality',
  position: 'position',
  vessel: 'vessel',
  englishLevel: 'languages',
  jobStatus: 'jobStatus',
  dpLicense: 'dpLicense',
  travelDocuments: 'travelDocuments',
  certifications: 'certifications',
  endorsements: 'endorsements',
  totalWorkExperienceYears: 'totalWorkExperienceYears',
  nearestAirport: 'nearestAirport',
  'experiences.imoNumber': 'recordOfSeaServices.imoNumber',
  visas: 'travelDocuments.visa',
  cv: 'curriculumVitae',
  companyCandidateAccess: 'companyCandidateAccess',
  companyCandidateAccessType: 'companyCandidateAccess',
  jobApplications: 'jobApplications',
}

function convertAgeToDates(startAge?: number, endAge?: number): string {
  const startDate = moment().subtract(endAge, 'years').startOf('year').format('YYYY-MM-DD')
  let endDate = ''

  if (endAge) {
    endDate = moment().subtract(startAge, 'years').endOf('year').format('YYYY-MM-DD')
  }

  const isStartAgeOver65 = startAge === 65

  const constructAgeFilterString = `${!isStartAgeOver65 ? `$gte:${startDate}` : ''}${
    !isStartAgeOver65 ? '&filter.birthdate=' : ''
  }$lte:${!isStartAgeOver65 ? endDate : moment().subtract(startAge, 'years').startOf('year').format('YYYY-MM-DD')}`

  return constructAgeFilterString
}

export const ApiPathsSeafarers = {
  candidates: ({ page, rows, filters, sortBy, sortField, sortOrder, search }: IPageFilters) => {
    let queryString = `/candidates?page=${page}&limit=${rows}`

    if (search) {
      queryString += `&search=${search}`
    }

    if (sortBy) {
      queryString += `&sortBy=${sortBy}`
    }

    if (sortField) {
      queryString += `&sortBy=${sortField
        .replace('acceptedNationalities', 'nationality.name')
        .replace('position', 'position.name')}:${sortOrder === 1 ? 'ASC' : 'DESC'}`
    }

    if (filters) {
      for (const key in filters) {
        if (filters[key] !== undefined) {
          const filterData = filters[key]
          const suffix = filterSuffix[key as keyof IFilterSuffix]

          if (suffix) {
            if (Array.isArray(filterData)) {
              const values = filterData.map((item) => {
                if (
                  key === 'totalWorkExperienceYears' ||
                  key === 'companyCandidateAccess' ||
                  key === 'companyCandidateAccessType' ||
                  key === 'jobApplications'
                ) {
                  if (item.name === 'All') {
                    return 0
                  }

                  return item.name
                }

                if (key === 'age') {
                  const filter = filters?.[key]
                  const result = convertAgeToDates(filter[0].minValue, filter[0].maxValue)
                  return result
                }

                if (typeof item === 'object') {
                  return item.id || item.value || item.name
                }

                return item
              })

              if (values.length > 0) {
                if (key === 'nearestAirport') {
                  values[0] = (values[0] as string).toLowerCase()
                }
                queryString += `&filter.${keyToRequest[key as keyof IFilterSuffix]}${suffix}${values.join(',')}`
              }
            } else {
              queryString += `&filter.${keyToRequest[key as keyof IFilterSuffix]}${suffix}${
                (filterData as IFilterValue).id
              }`
            }
          }
        }
      }
    }

    return queryString
  },
  candidate: (id: string) =>
    `/v4/candidates/${id}?relations=workExperiences,jobStatus,travelDocuments,certifications,certifications.issuedBy,endorsements,endorsements.issuedBy,recordOfSeaServices,visas,jobApplications,education,languages,candidateAnsweredQuestion,medicalCertificate,nationality,position,curriculumVitae`,
  lastRegisteredCandidates: `public/candidates/last-registered`,
}

export const getOneSeafarer = async (url: string): Promise<ISeafarer> => {
  const { data } = await http.get(url)

  return data
}

export const getSeafarers = async (url: string): Promise<ISeafarerMultipleResponse> => {
  const { data } = await http.get(url)

  return data
}
