import { TFunction } from 'i18next'
import { FC } from 'react'
import CompanyLastRegistered from 'services/landing-page-company/components/companyLastRegistered'
import { IndividualsCompaniesLogo } from 'services/landing-page-individuals/components/individualsCompanies'
import IndividualsCompaniesJobs from 'services/landing-page-individuals/components/individualsCompaniesJobs'
import styled from 'styled-components'
import { checkPage } from '../landingPage.utils'

interface IIndividualsJoinUs {
  t: TFunction<string, undefined>
  page?: string
}

const IndividualsJoinUs: FC<IIndividualsJoinUs> = ({ t, page = '/' }) => {
  return (
    <Div>
      <div className="companies-title mt-2 md:mt-0 text-left text-3xl md:text-center text-4xl md:text-5xl w-full">
        {t('collaborating_for_a_better_feature')}
      </div>
      <div className="w-full flex-1 align-items-center justify-content-center align-content-center">
        <IndividualsCompaniesLogo />
      </div>
      <div className="companies-title text-left md:text-center text-3xl md:text-4xl md:text-5xl md:mt-5 w-full ">
        {checkPage(page) ? t('last_registered_profiles-title') : t('latest_job_posted')}
      </div>
      <div className="companies-jobs relative">
        {checkPage(page) ? <CompanyLastRegistered t={t} /> : <IndividualsCompaniesJobs t={t} />}
      </div>
    </Div>
  )
}

export default IndividualsJoinUs

const Div = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary_10};
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-inline: 1rem;
  padding-top: 0.675rem;
  padding-bottom: 14rem;

  .companies-title {
    font-weight: 700;
  }

  .companies-jobs {
    max-width: 1460px;
    width: 100%;
    min-height: 10rem;
    margin-bottom: 3rem;
  }
  @media screen and (min-width: 1024px) {
    padding-inline: 3rem;
  }

  @media screen and (min-width: 1920px) {
    padding-inline: 230px;
    padding-top: 5rem;
    padding-bottom: 20rem;
  }
`
