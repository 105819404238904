import styled, { css } from 'styled-components'

export const StyledEllipsisContainer = styled.div<{ verticalRows?: number; maxWidth?: string }>`
  width: calc(99%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};

  ${({ verticalRows }) =>
    verticalRows &&
    css`
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: ${verticalRows};
        -webkit-box-orient: vertical;
      }
    `};
`
