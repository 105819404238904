import PersonPlaceholder from 'assets/landing-page/personPlaceholder.png'
import { LoadingBox } from 'common/components/States/Loading/LoadingBox'
import { useGet } from 'common/hooks/useApi'
import { TFunction } from 'i18next'
import Image from 'next/image'
import { FC } from 'react'
import { ApiPathsSeafarers } from 'services/seafarers/seafarers.api'
import styled from 'styled-components'

interface ICompanyLastRegistered {
  t: TFunction<'landing-page-company', undefined>
}

interface ILastRegisteredCandidate {
  position: string
  ship: string | null
  records: string
  image: string | null
  id: string
}

const CompanyLastRegistered: FC<ICompanyLastRegistered> = ({ t }) => {
  const { data, isLoading } = useGet<ILastRegisteredCandidate[]>(ApiPathsSeafarers.lastRegisteredCandidates)

  return (
    <BigContainerDiv className="gap-3 mt-3 md:mt-5 mb-7 md:mb-5">
      {isLoading && <LoadingBox size={56} />}
      {!isLoading &&
        data?.map((person, index) => (
          <Div key={index}>
            <Image
              src={PersonPlaceholder}
              alt="Avatar"
              width={56}
              height={56}
              className="border-circle new_user-image"
            />
            <div className="flex flex-column  gap-2">
              <span className="new_user-title">
                {person?.position && person?.position.length > 28
                  ? person.position.slice(0, 25) + '...'
                  : person.position}
              </span>
              <span className="new_user-company_name">{person.ship ?? 'Ship not mentioned'}</span>
              <span className="new_user-vessel">{person.records} sea records</span>
              {/* {person.id && (
                <span>
                  <Button variant="primary" onClick={() => router.push(pagesPaths.candidatePublicView(person.id))}>
                    {t('view_profile')}
                  </Button>
                </span>
              )} */}
            </div>
          </Div>
        ))}
    </BigContainerDiv>
  )
}

const BigContainerDiv = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr;
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 574px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1660px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Div = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  gap: 1.275rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.05));
  padding: 1rem;

  .new_user-image {
    margin-top: 0.75rem;
  }

  .new_user-title {
    font-weight: 600;
    font-size: 20px;
  }

  .new_user-company_name {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary_blue};
  }

  .new_user-vessel {
    font-size: 14px;
    font-weight: 500;
  }

  .new_user-sallary {
    font-weight: 500;
  }

  @media screen and (max-width: 615px) {
    height: 170px;
  }
`
export default CompanyLastRegistered
