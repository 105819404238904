import ImageHedera from 'assets/landing-page/ImageHedera.png'
import HbarLogoSrc from 'assets/landing-page/hbar-logo.png'
import BladeWallet from 'assets/landing-page/BladeWallet.svg'
import DownloadApp from 'common/components/DownloadApp/DownloadApp'
import { TFunction } from 'i18next'
import Image from 'next/image'
import styled from 'styled-components'

const IndividualsHedera = ({ t }: { t: TFunction<string, undefined> }) => {
  return (
    <StyledHedera>
      <div className="footer-showcase">
        <div className="container mx-auto">
          <div className="details flex flex-column justify-content-center">
            <h2>{t('harnessing')}</h2>
            <p className="mt-3">
              {t('collaboration_with_the_Hedera_Foundation')}
              <br /> <br />
              {t('join_us_today')}
            </p>
            <div className="partnerships flex flex-column gap-2 py-4">
              {t('in_partnership_with')}
              <div className="flex flex-row gap-2">
                <Image src={HbarLogoSrc} alt="HbarLogo" width={247} height={66} />
                <BladeWallet width={247} height={60} />
              </div>
            </div>
            <DownloadApp text={t('download_app_now')} />
          </div>

          <div className="seaplify-container w-full text-right ">
            <Image src={ImageHedera} alt="Seaplify Shipping Container" width={557} height={489} />
          </div>
        </div>
      </div>
    </StyledHedera>
  )
}

export default IndividualsHedera

const StyledHedera = styled.div`
  .footer-showcase {
    padding: 2rem 0;
    padding-inline: 230px;

    > .container {
      max-width: 1440px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-content: center;
      gap: 1.5rem;
    }

    .download-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    text-align: left;
    background-color: ${({ theme }) => theme.colors.secondary_10};

    .details {
      small {
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.gray_85};
      }

      h2 {
        font-weight: 700;
        font-size: clamp(30px, 4vw, 40px);
        line-height: 130%;
        color: #272b39;
        font-weight: 700;

        span {
          color: ${({ theme }) => theme.colors.secondary_100};
        }
      }

      .partnerships {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #272b39;

        img {
          mix-blend-mode: multiply;
        }
      }
    }

    .seaplify-container {
      text-align: center !important;

      > img {
        width: 100%;
        height: 100%;
        max-width: 557px;
        max-height: 489px;
      }
    }
  }

  @media screen and (min-width: 1640px) {
    .footer-showcase > .container {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 1100px) {
    .footer-showcase {
      padding-inline: 100px;
    }
  }

  @media screen and (max-width: 768px) {
    .footer-showcase {
      padding: 2rem 1rem;
    }

    .partnerships {
      font-size: 13px !important;

      > img {
        width: 167px;
        height: 43px;
      }
    }
  }
`
