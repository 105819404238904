import CompanyPlaceholder from 'assets/landing-page/companyPlaceholder.png'
import { Button } from 'common/components/Button/Button'
import { LoadingBox } from 'common/components/States/Loading/LoadingBox'
import { handleError } from 'common/utils/validation/errorHandler'
import { TFunction } from 'i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useGetLatestJobs } from 'services/jobs/jobs.service'
import { getSalaryIconCurrency } from 'services/jobs/jobs.utils'
import styled from 'styled-components'
import { StyledEllipsisContainer } from 'styles/GlobalStyledComponents'
interface IIndividualsCompaniesJobs {
  t: TFunction<string, undefined>
}

const IndividualsCompaniesJobs: FC<IIndividualsCompaniesJobs> = ({ t }) => {
  const router = useRouter()
  const { data = [], isLoading } = useGetLatestJobs({
    onError: (error: any) => handleError(error),
  })

  if (isLoading) {
    return <LoadingBox size={32} minHeight="20vh" />
  }
  return (
    <BigContainerDiv className="gap-3 mt-3 md:mt-5 mb-7 md:mb-5">
      {data.map((job, index) => (
        <Div key={index}>
          <div>
            <Image
              src={job?.company?.logo_file_path ?? CompanyPlaceholder}
              alt=""
              width={56}
              height={56}
              className="border-round-xs company_job-image"
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div className="w-full flex flex-column justify-content-center gap-2">
            <span className="company_job-title">
              <StyledEllipsisContainer maxWidth="95%" verticalRows={2}>
                {job?.title}
              </StyledEllipsisContainer>
            </span>
            <span className="company_job-company_name">{job.company.name}</span>
            <span className="company_job-vessel">{job?.vessel?.name ?? t('vessel_not_mentioned')}</span>
            <span className="company_job-sallary">
              {job.salaryFrom && (
                <>
                  {!job.salaryTo && t('starting_from')}
                  <i className={getSalaryIconCurrency(job.salaryCurrency)} />
                  {job.salaryFrom}
                </>
              )}
              {job.salaryTo && (
                <>
                  {!job.salaryFrom ? t('up_to') : ' - '}
                  <i className={getSalaryIconCurrency(job.salaryCurrency)} />
                  {job.salaryTo}
                </>
              )}
              {!job.salaryTo && !job.salaryFrom && t('to_be_confirmed')}
            </span>
            <span>
              <Button
                variant="primary"
                onClick={() => router.push(`/jobs/${job.slug ? job.slug[job.slug?.length - 1] : job.id}/view`)}
              >
                <Link href={`/jobs/${job.slug ? job.slug[job.slug?.length - 1] : job.id}/view`}>{t('view_job')}</Link>
              </Button>
            </span>
          </div>
        </Div>
      ))}
    </BigContainerDiv>
  )
}

const BigContainerDiv = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  max-width: 1460px;
  width: 100%;
  place-items: center;
  margin: 0 auto;

  @media screen and (min-width: 574px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Div = styled.div`
  width: 100%;
  min-height: 175px;
  height: 100%;
  max-width: 470px;
  display: flex;
  gap: 1.275rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.05));
  padding: 1rem;

  .company_job-image {
    margin-top: 0.75rem;
    object-fit: contain;
  }

  .company_job-title {
    font-weight: 600;
    font-size: 20px;
    word-break: break-word;
  }

  .company_job-company_name {
    font-size: 14px;
  }

  .company_job-vessel {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.secondary_blue};
  }

  .company_job-sallary {
    font-weight: 500;
  }
`
export default IndividualsCompaniesJobs
