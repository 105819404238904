import { TFunction } from 'i18next'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

interface IIndividualsBlog {
  t: TFunction<string, undefined>
  blogList: {
    title: string
    url: string
    date: string
    category: string
    readingTime: string
    image: StaticImageData
  }[]
}
const IndividualsBlog: FC<IIndividualsBlog> = ({ blogList, t }) => {
  return (
    <Div className="gap-3 mb-2 md:mb-7">
      {blogList.map((blog, index) => (
        <div key={index} className="explore_blog-card">
          <div>
            <Image src={blog.image} alt="Blog Image" />
          </div>
          <div className="flex flex-wrap justify-content-between align-items-center my-2">
            <CategoryDiv className="py-1">{blog.category}</CategoryDiv>
            <div className="blog-time ">
              {t('reading_time')} {blog.readingTime}
            </div>
          </div>
          <Link className="explore_blog-title mb-3" href={blog.url} passHref legacyBehavior>
            <a target="_blank">
              <span className="font-semibold text-xl md:text-2xl">
                {blog.title.length <= 70 ? blog.title : blog.title.slice(0, 70) + '...'}
              </span>
            </a>
          </Link>
          <div className="explore_blog-date mt-2 md:mt-3">{blog.date}</div>
        </div>
      ))}
    </Div>
  )
}

export default IndividualsBlog

const CategoryDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary_25};
  border-radius: 24px;
  padding-inline: 1rem;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  line-height: 20px;
`

const Div = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 262px;
  }

  @media screen and (min-width: 910px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
