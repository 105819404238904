import PersonImage from 'assets/landing-page/PersonImage.png'
import PersonImage1 from 'assets/landing-page/PersonImage1.png'
import NaviCrew from 'assets/landing-page/logo navicrew final.jpg'
import Maersk from 'assets/landing-page/maersk.svg'
import Orca from 'assets/landing-page/orca.svg'
import Star from 'assets/landing-page/star.svg'
import Image from 'next/image'
import { Carousel } from 'primereact/carousel'
import { FC } from 'react'
import { Review } from 'services/landing-page-individuals/landingPage.types'
import styled from 'styled-components'
interface IReviewsCarousel {
  type: number
}

const ReviewsCarousel: FC<IReviewsCarousel> = ({ type }) => {
  const reviewsIndividuals = [
    {
      id: '0',
      image: PersonImage,
      firstName: 'Sarah',
      lastName: 'Johnson',
      position: 'Deck Officer',
      vote: 5,
      message: "Seaplify's Wiki and Digital Library are lifesavers. I found my job through the app too!",
    },
    {
      id: '1',
      image: PersonImage1,
      firstName: 'Kevin',
      lastName: 'Smith',
      position: 'Maritime Researcher',
      vote: 5,
      message: "Seaplify has simplified my maritime journey. From the job board to the quizzes, it's all top-notch.",
    },
    {
      id: '2',
      image: PersonImage,
      firstName: 'Robert',
      lastName: 'Allen',
      position: '2nd Engineer',
      vote: 5,
      message: 'The UI is so user-friendly, and the Wiki section is a real lifesaver when I have quick questions.',
    },
    {
      id: '3',
      image: PersonImage,
      firstName: 'Bill',
      lastName: 'Harris',
      position: 'Able Seaman',
      vote: 5,
      message: "Seaplify has everything I need. It's like a Swiss Army knife for the maritime industry.",
    },
    {
      id: '4',
      image: PersonImage,
      firstName: 'Carlos',
      lastName: 'Martinez',
      position: 'Chief Engineer',
      vote: 5,
      message: "For someone in maritime, Seaplify is the perfect tool. It's simple and extremely useful.",
    },
    {
      id: '5',
      image: PersonImage,
      firstName: 'Victor',
      lastName: 'Emenike',
      position: 'Electrician',
      vote: 5,
      message: 'I can find job openings, study materials, and even prepare for exams. Seaplify is amazing!',
    },
  ]

  const reviewsCompany = [
    {
      id: '0',
      image: <Orca />,
      firstName: 'Mădălina',
      lastName: 'Condeescu',
      position: 'Director ORCA Crew Services',
      vote: 5,
      message: 'The website is user-friendly and straightforward, the platform is designed to facilities easy access.',
    },
    {
      id: '1',
      image: <Maersk />,
      firstName: 'Iulia',
      lastName: 'Fleancu',
      position: 'Senior Marine People Advisor',
      vote: 5,
      message:
        'Seaplify mobile app provides a seamless platform for us to connect with highly skilled professionals and find the suitable candidates for our job openings. Although the app is quite new on the market, its potential on becoming top most used platform for the industry, is outstanding, given its innovation, user friendly, intuitive, catchiness, ludic appearance.',
    },
    {
      id: '2',
      image: <Image src={NaviCrew} width={56} height={56} alt="///" />,
      firstName: 'Claudia',
      lastName: 'Oprea',
      position: 'Crew Manager',
      vote: 5,
      message:
        'Easy to work with, all at hand, very efficient, saves time for us, up to date, innovative. Definitely a very useful tool for agencies and seafarers.',
    },
  ]

  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ]

  const displayReviewVote = (number: number) => {
    const newScore = new Array(number).fill(<Star />)

    return newScore.map((elem: any, index: number) => <span key={index}>{elem}</span>)
  }

  const productTemplate = (review: Review) => {
    return (
      <Div className="border-1 surface-border border-round-2xl m-2 text-center py-5 px-3" key={review.id}>
        <div className="flex gap-3 align-items-center mb-2">
          {type ? <div className="carousel-image">{review.image as any}</div> : ''}
          <div className="flex flex-column gap-1 text-left">
            <p className="primary-font reviewer-name">{`${review.firstName} ${review.lastName}`}</p>
            <p className="primary-font reviewer-position">{review.position}</p>
            <div>{displayReviewVote(review.vote)}</div>
          </div>
        </div>
        <div className="text-left">
          <p className="review-text primary-font">{review.message}</p>
        </div>
      </Div>
    )
  }

  return (
    <Carousel
      value={!type ? reviewsIndividuals : reviewsCompany}
      numScroll={1}
      numVisible={2}
      autoplayInterval={10000}
      responsiveOptions={responsiveOptions}
      itemTemplate={productTemplate}
      onTouchMove={() => {}}
    />
  )
}

export default ReviewsCarousel

const Div = styled.div`
  height: calc(100% - 10px);

  .carousel-image {
    max-width: 56px;
    max-height: 56px;
    width: 100%;
    border-radius: 50%;

    svg {
      width: 56px;
      height: 56px;
    }
  }

  .carousel-image-2 {
    object-fit: contain;
  }

  .reviewer-name {
    font-size: 15px;
  }

  .reviewer-position {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray_85};
  }

  .review-text {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey_100};
  }
`
