import StartRecruitingArrow from 'assets/landing-page/StartRecruitingArrow.png'
import { Button } from 'common/components/Button/Button'
import DownloadApp from 'common/components/DownloadApp/DownloadApp'
import { TFunction } from 'i18next'
import Head from 'next/head'
import Image, { StaticImageData } from 'next/image'
import { FC } from 'react'
import { checkPage } from 'services/landing-page-individuals/landingPage.utils'
import styled from 'styled-components'

interface IIndividualsHero {
  heroImage: StaticImageData
  t: TFunction<string, undefined>
  page: string
  redirectIfAuthenticated?: (urlYes: string, urlNo?: string) => void
}

const IndividualsHero: FC<IIndividualsHero> = ({ t, heroImage, page, redirectIfAuthenticated }) => {
  return (
    <>
      <Head>
        <link rel="preload" href={heroImage.src} as="image" />
      </Head>
      <Div className="mt-2 mb-5 sm:mb-8 sm:mt-8 gap-3">
        <div className="hero-description">
          <div className="mt-3 sm:mt-0">
            {t('title1')} <b className="primary_100">{t('titleHighlight')}</b> {t('title2')}
          </div>
          <p className="normal-text my-3">{checkPage(page) ? t('get_ready-text') : t('subtitle')}</p>
          {checkPage(page) ? (
            <div className="start-recruiting-container">
              <Button
                variant="primary"
                size="large"
                style={{ width: '214px', height: '62px' }}
                onClick={() => redirectIfAuthenticated && redirectIfAuthenticated(`/jobs`)}
              >
                {t('start_recruiting')}
              </Button>
              <Image
                src={StartRecruitingArrow}
                alt="Arrow image pointing to Start Recruiting button"
                className="recruiting-arrow"
              />
            </div>
          ) : (
            <DownloadApp text={t('download_app')} />
          )}
        </div>
        <div className="hero-image-container">
          <Image loading="eager" src={heroImage} alt="hero" className="hero-image" />
        </div>
      </Div>
    </>
  )
}

export default IndividualsHero

const Div = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-inline: auto;

  .hero-image-container,
  .hero-description {
    width: 100%;
    font-size: 50px;
    max-width: 800px;
  }

  .hero-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-image-container {
    text-align: center;
    padding-inline: 1rem;

    .hero-image {
      width: 100%;
      height: 100%;
      max-width: 600px;
      max-height: 600px;
    }
  }

  .start-recruiting-container {
    position: relative;
    margin-top: 20px;
    .recruiting-arrow {
      position: absolute;
      top: -20px;
      left: 240px;
    }
  }

  @media screen and (max-width: 768px) {
    .hero-description {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 1500px) {
    flex-direction: row;

    .hero-image-container,
    .hero-description {
      flex: 0 0 auto;
      padding: 0;
      width: 50%;
    }

    .hero-description {
      flex-direction: column;
      justify-content: center;
    }

    .hero-image-container {
      text-align: right;
    }
  }
`
