import Armada from 'assets/landing-page/armada.png'
import BalticShipping from 'assets/landing-page/balticShipping.png'
import CMACGM from 'assets/landing-page/cmacgm.png'
import FairSpirit from 'assets/landing-page/fairSpirit.png'
import GoodWinds from 'assets/landing-page/goodWinds.png'
import HanseaticConnect from 'assets/landing-page/hanseaticConnect.png'
import Icm from 'assets/landing-page/icm.png'
import NaviCrew from 'assets/landing-page/logo navicrew final.jpg'
import Lowland from 'assets/landing-page/lowland.png'
import Maersk from 'assets/landing-page/maersk.svg'
import MarineMan from 'assets/landing-page/marineMan.png'
import NeoPoint from 'assets/landing-page/neoPoint.png'
import Orca from 'assets/landing-page/orca.svg'
import PD from 'assets/landing-page/pd.png'
import RomTrust from 'assets/landing-page/romTrust.png'
import Tenet from 'assets/landing-page/tenet.png'
import WTSEnergy from 'assets/landing-page/wtsenergy.png'
import Image from 'next/image'
import styled from 'styled-components'

export const IndividualsCompaniesLogo = () => {
  return (
    <Div className="my-4 md:my-7 p-grid align-content-evenly">
      <div>
        <Orca style={{ width: '168px', height: '46px' }} />
      </div>
      <div>
        <Maersk style={{ width: '130px', height: '71px' }} />
      </div>
      <div>
        <Image src={Tenet} alt="Tenet" style={{ width: '100px', height: '90px' }} />
      </div>
      <div>
        <Image src={RomTrust} alt="Rom Trust" style={{ width: '151px', height: '47px' }} />
      </div>
      <div>
        <Image src={GoodWinds} alt="Good Winds" style={{ width: '106px', height: '59px' }} />
      </div>
      <div>
        <Image src={Icm} alt="ICM" style={{ width: '73px', height: '66px' }} />
      </div>
      <div>
        <Image src={NaviCrew} alt="Navi Crew" style={{ width: '73px', height: '73px' }} />
      </div>
      <div>
        <Image src={NeoPoint} alt="NeoPoint" style={{ width: '155px', height: '66px' }} />
      </div>
      <div>
        <Image src={FairSpirit} alt="Fair Spirit" style={{ width: '85px', height: '85px' }} />
      </div>
      <div>
        <Image src={PD} alt="PD" style={{ width: '64px', height: '67px' }} />
      </div>
      <div>
        <Image src={Lowland} alt="Lowland" style={{ width: '112px', height: '50px' }} />
      </div>
      <div>
        <Image src={BalticShipping} alt="Baltic Shipping" style={{ width: '63px', height: '63px' }} />
      </div>
      <div>
        <Image src={MarineMan} alt="Marine Man" style={{ width: '158px', height: '20px' }} />
      </div>
      <div>
        <Image src={HanseaticConnect} alt="Hanseatic  Connect" style={{ width: '112px', height: '67px' }} />
      </div>
      <div>
        <Image src={Armada} alt="Armada" style={{ width: '125px', height: '46px' }} />
      </div>
      <div>
        <Image src={CMACGM} alt="CMA CGM" style={{ width: '97px', height: '59px' }} />
      </div>
      <div>
        <Image src={WTSEnergy} alt="WTS Energy" style={{ width: '157px', height: '25px' }} />
      </div>
    </Div>
  )
}

const Div = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr;
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;

  > div {
    flex: 1;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    > img,
    > svg {
      width: 100%;
      max-width: 158px;
    }
  }

  @media screen and (min-width: 375px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: repeat(6, 1fr);
  }
`
